<!-- =========================================================================================
    File Name: CardStatistics.vue
    Description: Statistics Card
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="page">
    <form enctype="multipart/form-data">
    <vx-card>
      <vs-table v-if="formule!== null && formule !== undefined" :data="formule">
        <template slot-scope="{data}" v-if="data[0] !== undefined">
          <vs-tr>
            <vs-td class="headTab" style="width: 30%">
              Titre
            </vs-td>
            <vs-td >
              {{data[0].libelle}}
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Gratuit
            </vs-td>
            <vs-td :data="data[0].free">
              <vs-checkbox class="float-left" vs-value="true" v-model="data[0].free" @change="updatePrice"> Oui </vs-checkbox>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Liste des négociateurs<br>
              <small>Liste des nouveaux arrivants pour les offres débutants, Format csv</small>
            </vs-td>
            <vs-td>
              <vue-csv-import
                v-model="negociateurs"
                :map-fields="{'email' :'Email'  ,'trigramme' :'Trigramme' ,'dateDebut' :'Date de début (Format : Mois/Jour/Année) ', 'nbRdv' :'Nombre de rdv'}"
              >
                <template slot="error">
                  Le type est invalide
                </template>
                <template slot="hasHeaders" slot-scope="{headers, toggle}">
                  <label  style="display: none !important">
                    <input type="checkbox" id="hasHeaders" value="true" v-model="headers" @change="toggle">
                    Headers?
                  </label>
                </template>
                <template slot="thead">
                  <tr>
                    <th>Mes colonnes </th>
                    <th>Colonne</th>
                  </tr>
                </template>
                <template slot="next" slot-scope="{load}">
                  <button @click.prevent="load">Charger</button>
                </template>
              </vue-csv-import>  <a href="/modele_nouveaux_arrivants.csv"><feather-icon class="mr-2" icon="LinkIcon" svgClasses="w-4 h-4"/>Modèle du fichier</a> </vs-td>

          </vs-tr>
          <vs-tr v-if="data[0].choixNbRdv && !free">
            <vs-td class="headTab">
              Prix / Nombre de rendez-vous
            </vs-td>
            <vs-td :data="data[0].prix">
              <vs-tr>
                <vs-td>
                  <vs-input
                    class="w-full mt-6"
                    type="number"
                    placeholder="Prix"
                    :label="data[0].prix[0].nb+' RDV'"
                    v-model="data[0].prix[0].prix"
                    :disabled="free"
                  />
                </vs-td>
                <vs-td>
                  <vs-input
                    class="w-full mt-6"
                    type="number"
                    placeholder="Prix"
                    :label="data[0].prix[1].nb +' RDV'"
                    v-model="data[0].prix[1].prix "
                    :disabled="free"
                  />
                </vs-td>
              </vs-tr>
            </vs-td>
          </vs-tr>
          <vs-tr v-if=" !free ">
            <vs-td class="headTab">
              Choix de nombre de rendez-vous<br>
            </vs-td>
            <vs-td :data="data[0].choixNbRdv">
              <vs-checkbox class="float-left" vs-value="true" v-model="data[0].choixNbRdv"  :disabled="free"> Oui </vs-checkbox>
            </vs-td>
          </vs-tr>
          <vs-tr v-if="!data[0].choixNbRdv && !free ">
            <vs-td class="headTab" >
              Prix / Nombre de rendez-vous fixe<br>
              <small>Si le négociateur ne peut pas choisir le nombre de rendez-vous, veuillez fixer le nombre et le prix pour tous les négociateurs</small>

            </vs-td>
            <vs-td :data="data[0].prix">
              <vs-tr>
                <vs-td>
                  <vs-input
                    class="w-full mt-6"
                    type="number"
                    placeholder="Nombre Rdv"
                    label="Nombre de rendez-vous"
                    v-model="NombreRdvDefaut"
                  />
                </vs-td>
                <vs-td>
                  <vs-input
                    class="w-full mt-6"
                    type="number"
                    placeholder="Prix"
                    label="Prix"
                    :disabled="free"
                    v-model="prixDefaut"
                  />
                </vs-td>
              </vs-tr>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Description
            </vs-td>
            <vs-td :data="data[0].description">
              <ckeditor :editor="editor" v-model="data[0].description" :config="editorConfig"></ckeditor>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Icone<br>
              <small>(50px * 57px)</small>
            </vs-td>
            <vs-td >
              <img v-if="data[0].mediaIcone !== null && data[0].mediaIcone !== undefined" :src="baseURL+data[0].mediaIcone.contentUrl" style="width: 50px">
              <vs-input
                class="w-full mt-6"
                type="file"
                :name="uploadFieldName"
                id="mediaIcone"
                v-model="iconeFile"
                accept="image/*"
                @change="filesChange($event.target.name, $event.target.files,$event.target.id); fileCount = $event.target.files.length"
              />
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Bandeau<br>
              <small>(1920px * 272px)</small>
            </vs-td>
            <vs-td>
              <img v-if="data[0].mediaBandeau !== null && data[0].mediaBandeau !== undefined" :src="baseURL+data[0].mediaBandeau.contentUrl" style="width: 100%">
              <vs-input
                class="w-full mt-6"
                type="file"
                :name="uploadFieldName"
                id="mediaBandeau"
                v-model="bandeauFile"
                accept="image/*"
                @change="filesChange($event.target.name, $event.target.files,$event.target.id); fileCount = $event.target.files.length"
              />
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Text bandeau
            </vs-td>
            <vs-td :data="data[0].textBandeau">
              <ckeditor :editor="editor" v-model="data[0].textBandeau" :config="editorConfig"></ckeditor>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Nombre de rendez-vous à créditer
            </vs-td>
            <vs-td :data="data[0].defaultNbRdvCredit">
              <vs-input
                class="w-full mt-6"
                type="number"
                v-model="data[0].defaultNbRdvCredit"
              />
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Limite de recrédit par rapport au nombre de rendez-vous commandés
            </vs-td>
            <vs-td :data="data[0].limiteRecredit">
              <vs-checkbox class="float-left" vs-value="true" v-model="data[0].limiteRecredit"> Oui </vs-checkbox>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Affichage
            </vs-td>
            <vs-td>
              <vs-checkbox vs-value="true"  class="float-left" v-model="data[0].homePage"> Page d'accueil </vs-checkbox>
              <vs-checkbox vs-value="true"  v-model="data[0].basPage"> Bandeau en bas de la page d'accueil (Pour l'offre débutant) </vs-checkbox>
            </vs-td>
          </vs-tr>
          <vs-tr>
            <vs-td class="headTab">
              Active
            </vs-td>
            <vs-td :data="data[0].active">
              <vs-checkbox class="float-left" vs-value="true" v-model="data[0].active"> Oui </vs-checkbox>
            </vs-td>
          </vs-tr>

        </template>
      </vs-table>
      <vs-button @click="Update()" class="mr-3 mb-2 mt-10" >Mettre à jour</vs-button>
    </vx-card>
    </form>
  </div>
</template>

<script>
import moment from 'moment';
import { VueCsvImport } from 'vue-csv-import';
import axios from '@/axios';
import { upload } from '../../../../FileServices/file-upload.service.js';
import router from '@/router';

const STATUS_INITIAL = 0; const STATUS_SAVING = 1; const STATUS_SUCCESS = 2; const
  STATUS_FAILED = 3;
const baseURL = process.env.VUE_APP_BASE_URL;
export default {
  components: {
    VueCsvImport,
  },
  filters: {
    formDate(value) {
      if (value) {
        return moment(String(value)).format('DD/MM/YYYY HH:mm:ss');
      }
    },
  },
  props: {
    id: '',
  },
  data() {
    return {
      baseURL,
      NombreRdvDefaut: 0,
      prixDefaut: 0,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: null,
      uploadFieldName: 'file',
      negociateurs: [],
      negociateursObjects: [],
      formule: [],
      iconeFile: '',
      bandeauFile: '',
      free: false,
      editorConfig: {
        toolbar: [
          { name: 'clipboard', items: ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo'] },

          { name: 'tools', items: ['Maximize'] },
          '/',
          { name: 'basicstyles', items: ['Bold', 'Italic', 'Strike', '-', 'RemoveFormat'] },
          { name: 'paragraph', items: ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote'] },
          { name: 'styles', items: ['Styles', 'Format'] },
        ],
      },
      formData: [],
    };
  },
  computed: {
    bandeau() {
      return this.formule.bandeau;
    },
    icone() {
      return this.formule.icone;
    },
    isInitial() {
      return this.currentStatus === STATUS_INITIAL;
    },
    isSaving() {
      return this.currentStatus === STATUS_SAVING;
    },
    isSuccess() {
      return this.currentStatus === STATUS_SUCCESS;
    },
    isFailed() {
      return this.currentStatus === STATUS_FAILED;
    },
  },
  methods: {
    forceFileDownload(response) {
      const csvContent = response.data;
      const link = window.document.createElement('a');
      link.setAttribute('href', `data:text/csv;charset=utf-8,%EF%BB%BF${encodeURI(csvContent)}`);
      link.setAttribute('download', 'upload_data.csv');
      link.click();
    },
    updatePrice() {
      if (this.formule[0].free) {
        this.formule[0].prix[0].prix = 0;
        this.formule[0].prix[1].prix = 0;
        this.prixDefaut = 0;
        this.free = true;
        this.formule[0].choixNbRdv = false;
      } else {
        this.free = false;
      }
    },
    reset() {
      // reset form to initial state
      this.currentStatus = STATUS_INITIAL;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
    async save(formData) {
      // upload data to the server
      this.currentStatus = STATUS_SAVING;

      await upload(formData)
        .then((x) => {
          this.uploadedFiles = this.uploadedFiles.concat(x);
          this.currentStatus = STATUS_SUCCESS;
        })
        .catch((err) => {
          this.uploadError = err.response;
          this.currentStatus = STATUS_FAILED;
        });
    },
    async saveAll(formData, formule) {
      for (const value in formData) {
        await this.save(this.formData[value].formData).then(() => {
          const media = this.formData[value].fieldId;
          formule[media] = `/api/media_objects/${this.uploadedFiles[value].id}`;
        });
      }
      return formule;
    },
    filesChange(fieldName, fileList, fieldId) {
      // handle file changes
      const formData = new FormData();

      if (!fileList.length) return;

      // append the files to FormData
      Array
        .from(Array(fileList.length).keys())
        .map((x) => {
          formData.append(fieldName.replaceAll(' ', '-'), fileList[x], (fileList[x].name).replaceAll(' ', '-'), fieldId);
          const entry = { fieldId: formData };
          entry.fieldId = fieldId;
          entry.formData = formData;
          this.formData.push(entry);
        });
    },
    Update() {
      if (this.formule[0].active === null) {
        this.formule[0].active = false;
      }
      if (this.formule[0].choixNbRdv === null) {
        this.formule[0].choixNbRdv = false;
      }
      if (this.formule[0].homePage === null) {
        this.formule[0].homePage = false;
      }
      if (this.formule[0].basPage === null) {
        this.formule[0].basPage = false;
      }
      const formule = {
        libelle: this.formule[0].libelle,
        prix: this.formule[0].prix,
        description: this.formule[0].description,
        defaultNbRdvCredit: parseInt(this.formule[0].defaultNbRdvCredit),
        homePage: Boolean(this.formule[0].homePage),
        basPage: Boolean(this.formule[0].basPage),
        choixNbRdv: Boolean(this.formule[0].choixNbRdv),
        free: Boolean(this.formule[0].free),
        textBandeau: this.formule[0].textBandeau,
        limiteRecredit: Boolean(this.formule[0].limiteRecredit),
        active: Boolean(this.formule[0].active),
      };
      if (!this.formule[0].choixNbRdv) {
        formule.prix = [{
          nb: this.NombreRdvDefaut,
          prix: this.prixDefaut,
        },
        ];
      }
      this.saveAll(this.formData, formule).then((formule) => {
        axios.put(`/api/formule_commandes/${this.id}`, formule, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('access_token')}`,
          },
        }).then(() => {
          if (this.negociateurs.length > 0) {
            axios.post('/api/setNewMandataries', this.negociateurs, {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('access_token')}`,
              },
            }).then((response) => {
              if (response.status !== 208) {
                this.forceFileDownload(response);
                this.$vs.notify({
                  title: 'Certains négociateurs sont introuvables',
                  text: "Mise à jour effectué avec succès, mais certains négociateurs sont introuvables! Un télechargement de la liste des introuvables vient d'être lancé. ",
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'warning',
                  time: 8000,
                });
              } else {
                this.$vs.notify({
                  title: 'Import nouveaux arrivants effectué avec succès',
                  text: response.data,
                  iconPack: 'feather',
                  icon: 'icon-alert-circle',
                  color: 'success',
                  time: 8000,
                });
                router.push({ name: 'admin-formulas' });
              }
            })
              .catch(() => {
                this.$toasted.show('Veuillez vérifier vos champs!').goAway(1500);
              });
          } else {
            this.$vs.notify({
              title: 'Succès',
              text: 'Mise à jour avec succès',
              iconPack: 'feather',
              icon: 'icon-alert-circle',
              color: 'success',
              time: 5000,
            });
            router.push({ name: 'admin-formulas' });
          }
        })
          .catch((error) => {
            this.$toasted.show(error).goAway(1500);
          });
      });
    },
    getFormule() {
      axios.get(`/api/formule_commandes?id=${this.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access_token')}`,
        },
      }).then((response) => {
        if (response.data !== null) {
          this.formule = response.data;
          if (response.data[0].choixNbRdv === undefined || !response.data[0].choixNbRdv) {
            this.NombreRdvDefaut = this.formule[0].prix[0].nb;
            this.prixDefaut = this.formule[0].prix[0].prix;
            this.formule[0].prix = [{
              nb: 5,
              prix: 0,
            },
            { nb: '10', prix: 0 },
            ];
          }
          this.updatePrice();
        }
      })
        .catch((error) => {
          this.$toasted.show(error).goAway(1500);
        });
    },
  },
  mounted() {
    this.reset();
    this.getFormule();
  },
};
</script>
<style>
  .vs-table--tbody-table tr {
    -webkit-transition: all .3s ease;
    transition: all .3s ease;
    background: #fff;
    border: 1px solid #efefef;
  }
</style>
